export const DiscountMethods = {
  percentage: "%",
  fixed_amount: "円",
} as const
export type DiscountMethods = keyof typeof DiscountMethods

export const CouponEventType = [
  "",
  "publish_at_initial_purchase",
  "line",
  "others",
  "rank_up",
  "birthday",
  "important_person_birthday",
] as const
export type CouponEventType = keyof typeof CouponEventType

export const CouponPatternTargetTable = ["category", "product", "sku"] as const
export const CouponPatternOperandType = ["and", "or"] as const
export const CouponPatternUnit = ["type", "quantity"] as const

/**
 * システムで利用可能なイベント型取得クーポンのタイプ
 * - issue #3122 によって、publish_at_initial_purchase, line の運用停止
 */
export const CouponEventTypes = {
  "": "なし",
  others: "その他",
  rank_up: "ランクアップ",
  birthday: "誕生日",
  important_person_birthday: "大切な人の誕生日",
} as const
export type CouponEventTypes = keyof typeof CouponEventTypes

export const CouponTypes = {
  code: "コード型クーポン",
  acquisition: "獲得型クーポン",
  event: "イベント取得型クーポン",
  exchange: "交換型クーポン",
} as const
export type CouponTypes = keyof typeof CouponTypes

export const CouponUsageLocation = {
  online: "オンライン",
  offline: "直営店舗",
  can_be_used: "併用可能",
} as const
export type CouponUsageLocationType = keyof typeof CouponUsageLocation
